import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "@reach/router"

const exhibitionsQuery = graphql`
  query getExhibitionsQuery {
    allContentfulExhibition {
      nodes {
        venue
        name
        startDate
        endDate
        status
        thumbnail {
          id
          title
          file {
            url
          }
        }
        shortDescription {
          shortDescription
        }
        description {
          json
        }
      }
    }
    allContentfulProject(filter: { status: { eq: false } }) {
      nodes {
        name
        thumbnail {
          id
          title
          file {
            url
          }
        }
        shortDescription {
          shortDescription
        }
        description {
          json
        }
      }
    }
  }
`

const months = [
  "januari",
  "februari",
  "mars",
  "april",
  "maj",
  "juni",
  "juli",
  "augusti",
  "september",
  "oktober",
  "november",
  "december",
]

class ExhibitionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  encodeRoute(s) {
    return s
      .toLowerCase()
      .replace(/[, ]+/g, " ")
      .trim()
      .replace(/(^\s+|[^a-zA-Z0-9 ]+|\s+$)/g, "")
      .replace(/ /g, "-")
  }

  generateProjectEntry(node) {
    return (
      <div className={"row"} key={node.id} style={{ marginBottom: "25px" }}>
        <div className={"five columns"}>
          <img
            style={{ width: "100%" }}
            src={node.thumbnail.file.url}
            alt={node.thumbnail.title}
          />
        </div>
        <div className={"seven columns"}>
          <h2
            style={{
              fontWeight: "900",
              textTransform: "uppercase",
            }}
          >
            {node.venue}
          </h2>
          <h3 style={{ fontSize: "22px", fontStyle: "italic" }}>
            "{node.name}"
          </h3>
          <Link
            style={{ textDecoration: "none" }}
            to={"/project/" + this.encodeRoute(node.name)}
          >
            Läs mer om projektet
          </Link>
        </div>
      </div>
    )
  }

  generateEntry(node, startDate, endDate) {
    return (
      <div className={"row"} key={node.id} style={{ marginBottom: "25px" }}>
        <div key={node.thumbnail.title} className={"five columns"}>
          <img
            style={{ width: "100%" }}
            src={node.thumbnail.file.url}
            alt={node.thumbnail.title}
          />
        </div>
        <div key={node.thumbnail.file.url} className={"seven columns"}>
          <h2
            style={{
              fontWeight: "900",
              textTransform: "uppercase",
            }}
          >
            {node.venue}
          </h2>
          <h3 style={{ fontSize: "22px", fontStyle: "italic" }}>
            "{node.name}"
          </h3>
          <h3 style={{ fontSize: "18px", color: "#666666" }}>
            {startDate.getDate()} {months[startDate.getMonth()]},{" "}
            {startDate.getFullYear()} - {endDate.getDate()}{" "}
            {months[endDate.getMonth()]}, {endDate.getFullYear()}
          </h3>
          <Link
            style={{ textDecoration: "none" }}
            to={"/exhibition/" + this.encodeRoute(node.name)}
          >
            Läs mer om utställningen
          </Link>
        </div>
      </div>
    )
  }

  render() {
    return (
      <StaticQuery
        query={exhibitionsQuery}
        render={data => {
          return (
            <Layout>
              <SEO
                title="Utställningar"
                keywords={[`Utställningar`, `Projekt`, `Tidigare`, `Historia`]}
              />
              <div className={"row"} style={{ marginBottom: "25px" }}>
                <div className={"twelve columns"}>
                  <h5 style={{ fontWeight: "900" }}>AKTUELLA UTSTÄLLNINGAR</h5>
                </div>
              </div>

              <div className={"row"}>
                {data.allContentfulExhibition.nodes.map((node, index) => {
                  const startDate = new Date(node.startDate)
                  const endDate = new Date(node.endDate)

                  if (node.status) {
                    return this.generateEntry(node, startDate, endDate)
                  } else {
                    return null
                  }
                })}
              </div>

              <hr />

              <div className={"row"}>
                <div className={"twelve columns"}>
                  <h5 style={{ fontWeight: "900" }}>TIDIGARE UTSTÄLLNINGAR</h5>
                </div>
              </div>

              <div className={"row"}>
                {data.allContentfulExhibition.nodes.map(node => {
                  const startDate = new Date(node.startDate)
                  const endDate = new Date(node.endDate)

                  if (!node.status) {
                    return this.generateEntry(node, startDate, endDate)
                  } else {
                    return null
                  }
                })}
              </div>

              <hr />

              <div className={"row"}>
                <div className={"twelve columns"}>
                  <h5 style={{ fontWeight: "900" }}>TIDIGARE PROJEKT</h5>
                </div>
              </div>

              <div className={"row"}>
                {data.allContentfulProject.nodes.map(node => {
                  return this.generateProjectEntry(node)
                })}
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}

export default ExhibitionsPage
